'use strict';

const interval = setInterval(function () {
  if (document.getElementsByClassName('mainCarouselBanner')) {
    $('.mainCarouselBanner').slick({
      dots: true,
      infinite: true,
      lazyLoad: 'ondemand',
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      prevArrow: '<span class="slick-prev"></span>',
      nextArrow: '<span class="slick-next"></span>',
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: true,
            prevArrow: false,
            nextArrow: false
          }
        }
      ]
    });

    $('.mainCarouselBanner').on('setPosition', function(event, slick) {
      $('.mainCarouselContainer').removeClass('skeleton-mainBanner');
    });

    clearInterval(interval);
  }
}, 100);
